<template>
  <!-- 二级页面 联系我们-->
  <div class="twoPage">
    <!-- 头部 -->
    <swipeTop :ZHtitle="ZHtitle" :ENtitle="ENtitle" :top_img="top_img" />
    <!-- 搜索 -->
    <seachVue />
    <!-- 内容 -->
    <div class="categoryTitle">
      <van-icon style="transform: rotate(180deg);" name="play" />
      留言
      <van-icon name="play" />
    </div>
    <div class="content">
      <van-form ref="form" style="width: 80%;" @submit="onSubmit">
        <h3>姓名</h3>
        <van-field v-model="name" rows="3" autosize name="name" placeholder="请输入姓名" show-word-limit
                   :rules="[{ required: true, message: '请输入您的姓名' }]" />
        <h3>手机号</h3>
        <van-field v-model="phone" name="phone" placeholder="请输入手机号" show-word-limit :rules="[
            { required: true, message: '请填写您的手机号码！' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' }
          ]" />
        <h3>邮箱</h3>
        <van-field v-model="email" name="email" placeholder="请输入邮箱" show-word-limit :rules="[
            { required: true, message: '请填写您的邮箱！' },
            { pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/, message: '邮箱格式错误！' }
          ]" />
        <h3>想要了解的解决方案</h3>
        <van-field v-model="problemType" autocomplete="off" rows="3" @click="show = true" autosize name="problemType" placeholder="请输入想要了解的解决方案" show-word-limit
                   :rules="[{ required: true, message: '请输入想要了解的解决方案' }]" />
        <h3 v-if="isOther">想要了解的其他解决方案</h3>
        <van-field v-model="otherProblemType" v-if="isOther" autocomplete="off" rows="3" name="otherProblemType" placeholder="请输入想要了解的解决方案" show-word-limit
                   :rules="[{ required: true, message: '请输入想要了解的其他解决方案' }]" />
        <h3>想要了解的内容</h3>
        <van-field
            v-model="problemDescribe"
            rows="3"
            autosize
            type="textarea"
            name="problemDescribe"
            maxlength="500"
            placeholder="请输入想要了解的内容"
            show-word-limit
            :rules="[{ required: true, message: '请输入想要了解的内容' }]"
        />
        <!--          <van-field v-model="problemType" rows="3" autosize name="problemType" placeholder="请输入想要了解的内容" show-word-limit-->
        <!--            :rules="[{ required: true, message: '请输入想要了解的内容' }]" />-->
        <div style="margin: 60px 0;padding-bottom: 20px">
          <van-button round block type="info" :disabled="disabled" native-type="submit">提交</van-button>
        </div>
      </van-form>
      <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
    </div>
<!--    <div class="content">-->
<!--      <van-form ref="form" @submit="onSubmit">-->
<!--        <h3>留言类型</h3>-->
<!--        <van-field-->
<!--          readonly-->
<!--          clickable-->
<!--          name="problemType"-->
<!--          :value="problemType"-->
<!--          placeholder="问题类型"-->
<!--          @click="showPicker = true"-->
<!--          :rules="[{ required: true, message: '请选择反馈问题类型' }]"-->
<!--        />-->

<!--        <h3>留言内容</h3>-->
<!--        <van-field-->
<!--          v-model="problemDescribe"-->
<!--          rows="3"-->
<!--          autosize-->
<!--          type="textarea"-->
<!--          name="problemDescribe"-->
<!--          maxlength="50"-->
<!--          placeholder="请输入留言"-->
<!--          show-word-limit-->
<!--          :rules="[{ required: true, message: '请输入留言内容' }]"-->
<!--        />-->
<!--        <div style="margin: 60px 0">-->
<!--          <van-button-->
<!--            round-->
<!--            block-->
<!--            type="info"-->
<!--            :disabled="disabled"-->
<!--            native-type="submit"-->
<!--            >提交</van-button-->
<!--          >-->
<!--        </div>-->
<!--      </van-form>-->
<!--    </div>-->
    <!-- 选择器 -->
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import swipeTop from "@/components/swipeTop.vue";
import seachVue from "@/components/seachVue.vue";
import classifyList from "@/components/classifyList.vue";
  import { genMenuInfo, submitFeedback } from "@/api/path";

export default {
  components: {
    swipeTop,
    seachVue,
    classifyList,
  },
  data() {
    return {
      ZHtitle: "", // 页面中文标题
      ENtitle: "", // 页面英文标题
      subMenuList: [], // 分类
      toptype: [], // 导航栏内容
      top_img: require("@/assets/img/top-img.png"),
      menuId: this.$route.query.mid,
      // problemType: "",
      // problemDescribe: "",
      name: '',//姓名
      phone: '',//手机号
      email: '',//邮箱
      problemDescribe: '',//想要了解的品牌
      otherProblemType: '', // 其他想要了解的内容
      problemType: '',//想要了解的内容
      show: false,
      actions: [
        { name: '微生物检测' },
        { name: '过敏原检测' },
        { name: '临床诊断' },
        { name: '移植诊断' },
        { name: '其他' }
      ],
      showPicker: false,
      isOther: false,
      columns: ["产品咨询", "服务反馈", "临床应用", "其他"],
      disabled: false,
    };
  },
  created() {
    this.getInfo();
  },
  mounted() {
    // 监听返回事件
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.backButton, false);
    }
  },
  methods: {
    // 表单提交
    onSubmit(values) {
      if (this.isOther) {
        values.problemType = '其他-' + this.otherProblemType
      }
      submitFeedback(JSON.stringify(values))
        .then((result) => {
          this.$toast({
            type: "loading",
            message: "提交中",
            duration: 1500,
          });
          setTimeout(() => {
            if (result.code === 200) {
              this.$toast.success("留言成功");
              this.disabled = true;
              setTimeout(() => {
                this.disabled = false;
              }, 1500);
            }
          }, 1500);
        })
        .catch((err) => {
          this.$dialog
            .confirm({
              title: "留言失败",
              message: "请确认是否已登录",
              confirmButtonText: "去登录",
              cancelButtonText: "已登录",
            })
            .then(() => {
              this.$cookies.set("pathUrl", this.$route.fullPath);
              this.$router.push({
                path: "/loginPage",
              });
            })
            .catch(() => {});
        });
    },

    onConfirm(value) {
      this.problemType = value;
      this.showPicker = false;
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      this.problemType = item.name
      if (this.problemType === '其他') {
        this.isOther = true
      } else {
        this.isOther = false
        this.otherProblemType = ''
      }
    },
    // 获取数据
    getInfo() {
      genMenuInfo(this.menuId)
        .then((res) => {
          this.ZHtitle = res.data.menuName;
          this.ENtitle = res.data.menuEnglish;
          this.subMenuList = res.data.subMenuList;
          this.top_img = res.data.background;
          this.toptype = res.data.classificationMessageAlways;
        })
        .catch((err) => {});
    },
    // 三级页面
    toThree(item) {
      if (item.parentId === 12 && item.menuId === 48) {
        this.$router.push({
          path: "leveThreePageZXKT",
          query: { menuId: item.menuId },
        });
      } else {
        this.$router.push({
          path: "/levelThreePage",
          query: { menuId: item.menuId },
        });
      }
    },
    // 返回事件处理
    backButton() {
      this.$router.push({
        path: "/",
      });
    },
  },
  destroyed() {
    // 销毁页面返回事件
    window.removeEventListener("popstate", this.backButton, false);
  },
};
</script>

<style lang="scss" scoped>
.twoPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .content {
     height: 55.3%;
    background-color: #9d0a12;
    font-size: 3.5vw;
    display: flex;
    flex: 1;
    color: white;
    overflow: scroll;
    font-family: 'FZZZH',serif;
    justify-content: center;
    .itemBox {
      height: 83%;
      width: 95%;
      margin: auto auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      .item {
        width: 43%;
        height: 20vh;
        border-radius: 10px;
        background-color: #b25357;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px;
        img {
          width: 30%;
          margin-bottom: 30px;
        }
      }
    }
  }
}
.categoryTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vw;
  color: white;
  font-family: 'FZZZH',serif;
  background-color: #ac2126;
  background-image: url("../../assets/img/listbg.png");
}
.van-form {
  //margin-top: 10%;
  width: 87%;
  .van-field {
    margin-bottom: 34px;
  }
}
</style>
